export default class VodUtils {

    //获取签名
    public getSignature():any{
        let querystring = require("querystring");
        let crypto = require('crypto');

        // 确定 app 的云 API 密钥
        let secret_id = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
        let secret_key = "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA";

        // 确定签名的当前时间和失效时间
        let current = parseInt(String((new Date()).getTime() / 1000))
        let expired = current + 86400;  // 签名有效期：1天

        // 向参数列表填入参数
        let arg_list = {
            secretId : secret_id,
            currentTimeStamp : current,
            expireTime : expired,
            random : Math.round(Math.random() * Math.pow(2, 32)),
            oneTimeValid:0//是否单次有效（默认0，不是，1表示单次有效）
        }

        // 计算签名
        let orignal = querystring.stringify(arg_list);
        let orignal_buffer = new Buffer(orignal, "utf8");

        let hmac = crypto.createHmac("sha1", secret_key);
        let hmac_buffer = hmac.update(orignal_buffer).digest();

        let signature = Buffer.concat([hmac_buffer, orignal_buffer]).toString("base64");

        console.log(signature);
    }

    //上传
    public upload():any{
        // 安装稳定版本 npm install vod-js-sdk-v6
        // import TcVod from 'vod-js-sdk-v6'

        // 通过 import 引入的话，new TcVod(opts) 即可
        // new TcVod.default(opts) 是 script 引入 的用法


        /*const tcVod = new TcVod.default({
            getSignature: getSignature // 前文中所述的获取上传签名的函数
        })

        const uploader = tcVod.upload({
            mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
        })
        uploader.on('media_progress', (info:any)=> {
            console.log(info.percent) // 进度
        })

        // 回调结果说明
        // type doneResult = {
        //   fileId: string,
        //   video: {
        //     url: string
        //   },
        //   cover: {
        //     url: string
        //   }
        // }
        //uploader.cancel()//取消上传
        uploader.done().then( (doneResult:any)=> {
            // deal with doneResult
        }).catch((err:any)=> {
            // deal with error
        })*/
    }
}